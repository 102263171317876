<template>
  <div>
    <el-popover placement="right" width="300" trigger="hover" v-if="users.length">
      <div>
        <div class="border-bottom pb-2 scroll">
          <div class="documentLogs__title border-bottom d-flex align-items-center">
            <div class="pr-1 avatar-icon">
              <i class="el-icon-s-custom fs-3"></i>
            </div>
            <div>
              <h4 class="mb-0 text-dark ml-1">Users status</h4>
              <p class="logs-text text-muted ml-1">All enforce order users status</p>
            </div>
          </div>
          <div v-for="(user, i) in users" :key="'user_' + i" class="mt-1">
            <div class="logs-avatar">
              <div class="d-flex" v-if="user && user.user_name">
                <el-tag class="avatar-text color-6">{{ getInitialNames(user.user_name) }}</el-tag>
                <h5>{{ user.user_name }}</h5>
              </div>
              <div class="d-flex" v-else-if="user && user.batch_user_role">
                <el-tag class="avatar-text color-6">{{ getInitialNames(user.batch_user_role) }}</el-tag>
                <h5>{{user.batch_user_role }}</h5>
              </div>
              <div v-else class="not-sent-text">
                Not sent to next user
              </div>
              <el-tooltip :content="getUserContent(user)" placement="top">
                <el-tag
                  class="recipient-status capitalize"
                  :type="getUserAction(user) | getLogStatusType"
                >
                  <div class="status-tag-text">
                    <i :class="['iconStyling', getIconClass(user)]"></i>
                    {{
                    getUserAction(user)
                    | parseText }}
                  </div>
                </el-tag>
              </el-tooltip>
            </div>
            <div class="logs-content" v-if="user.sent_on">
              <p class="logs-date">
                <i class="el-icon-time"></i>
                <span class="date_time">{{ user.sent_on | globalDateTimeFormat }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <i class="el-icon-info" v-if="users.length" slot="reference"></i>
    </el-popover>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: [
    "currentFormbuilderData",
    "allEntitiesData",
    "allCompanyUserTypesData",
    "usersLogs"
  ],
  data() {
    return {
      users: [],
      entityNameObj: {},
      allCompanyUserTypesDataObj: {},
      loading: false,
      selectedUserIndex: null
    };
  },
  computed: {
    ...mapGetters("formbuilderData", ["getResendApprovalMailstatus"]),
    getLabel() {
      return userType => {
        if (userType && userType.role) {
          return userType.role.title + "-" + userType.name;
        } else {
          return userType.name;
        }
      };
    }
  },
  watch: {
    allEntitiesData: {
      async handler(data) {
        if (data.length) {
          (data || []).forEach(e => {
            this.$set(this.entityNameObj, e._id, e.name);
          });
        }
      },
      deep: true,
      immediate: true
    },
    allCompanyUserTypesData: {
      async handler(data) {
        if (data.length) {
          (data || []).forEach(e => {
            this.$set(this.allCompanyUserTypesDataObj, e._id, this.getLabel(e));
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    if (this.usersLogs) {
      this.users = [...this.usersLogs];
    }
    (this.allEntitiesData || []).forEach(e => {
      this.entityNameObj[e._id] = e.name;
    });
    (this.allCompanyUserTypesData || []).forEach(e => {
      this.allCompanyUserTypesDataObj[e._id] = this.getLabel(e);
    });
  },
  filters: {
    parseText(value) {
      return value.replace(/_/g, " ").toLowerCase();
    },
    getLogStatusType(status) {
      if (status === "COMPLETED") {
        return "success";
      } else if (status === "NOT_YET_SENT") {
        return "info";
      } else if (status == "SENT") {
        return "warning";
      }
      return "";
    }
  },
  methods: {
    getIconClass(user) {
      const status = this.getUserAction(user);
      if (status === "COMPLETED") {
        return "el-icon-success";
      } else if (status === "NOT_YET_SENT") {
        return "el-icon-info";
      } else if (status === "SENT") {
        return "el-icon-time";
      }
      return "";
    },
    getUserAction(user) {
      if (user?.status == "COMPLETED") {
        return "COMPLETED";
      } else if (user?.status == "SENT") {
        return "SENT";
      }
      return "NOT_YET_SENT";
    },
    getUserContent(user) {
      if (user?.status == "COMPLETED") {
        return user.user_name + " completed on " + user.completed_on;
      } else if (user?.status == "SENT") {
        return "Waiting for " + user.user_name;
      }
      return "The form cannot be sent to the next user until the previous user has completed it";
    },
    getInitialNames(name) {
      let result = "";
      if (name) {
        result = name[0];
        if (name.includes("-")) {
          result += name.split("-")[1][0];
        } else if (name.split(" ")?.[1]?.[0]) {
          result += name.split(" ")[1][0];
        }
      }
      return result.toUpperCase();
    },
  }
};
</script>
<style lang="scss" scoped>
.documentLogs__title {
  padding: 10px 15px;
}

.logs-avatar {
  display: flex !important;
  justify-content: space-between !important;

  .avatar-text {
    height: 35px;
    width: 35px;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 35px;
    text-align: center;
    border-radius: 30px;
    padding: 0;
    background-color: #ffffff;
  }

  h5 {
    margin-top: 7px;
    margin-left: 7px;
  }
}

.logs-content {
  position: relative;
}
.iconStyling {
  margin-right: 4px;
  font-size: 13px;
}
.avatar-icon {
  display: inline-block;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 3px;
}
.resendMail {
  margin-left: 220px;
  //color: #3c32a8;
  font-weight: 400;
}
.resendMailLoading {
  margin-left: 150px;
  font-size: 14px;
}
.scroll {
  scrollbar-width: thin;
  height: 37vh;
  overflow-y: auto;
}
@-moz-document url-prefix() {
  .recipient-status {
    margin-right: 12px !important;
  }
}
@supports (-webkit-appearance: none) {
  .recipient-status {
    margin-right: 5px;
  }
}
.resendDiv {
  margin-bottom: 0%;
}
.logs-text {
  word-break: break-word;
}
.status-tag-text{
  padding-left: 10px;
  padding-right: 10px;
}
.not-sent-text{
  color: #888;
  opacity: 0.8;
  margin-top: 7px;
}
</style>